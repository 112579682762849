import React from 'react'
// import { useNavigate } from 'react-router-dom'
import Travel from "../Assets/Travel.jpg"
import Certificate from './Certificate';
import Certificate2 from './Certificate2';
const AboutUs = () => {
    // const navigate = useNavigate();
    const handleExplore = ()=>{
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSdLuKhq4HnjdmXXVc0P2BDoFPKgwkkmhnXLCBmuSKaof1p4fQ/viewform', '_blank', 'noopener,noreferrer');
    }
  return (
    <div>
        <div className="sm:flex items-center max-w-screen-xl">
    <div className="sm:w-1/2 p-10">
        <div className="image object-center text-center">
            <img src={Travel} />
        </div>
    </div>
    <div className="sm:w-1/2 p-5">
        <div className="text">
            <span className="text-gray-500 border-b-2 border-indigo-600 uppercase">About us</span>
            <h2 className="my-4 font-bold text-3xl  sm:text-4xl ">About <span className="text-indigo-600">Our Company</span>
            </h2>
            <p className="text-gray-700">
            TrippyJiffy (Neelasha Travels LLP) is your go-to travel and tourism booking company, dedicated to making your travel dreams come true effortlessly and efficiently. We offer a comprehensive range of services, including hotel bookings, holiday packages, and custom travel itineraries. Whether you're planning a relaxing beach vacation, an adventurous trek, or a cultural exploration, TrippyJiffy ensures a seamless and enjoyable booking experience. Our user-friendly platform, expert travel advice, and competitive pricing make us the perfect partner for your next journey. Explore the world with ease and confidence, only with TrippyJiffy.
            </p>
        </div>
        <button 
        onClick={handleExplore}
        className='mt-3 px-5 py-5 text-white bg-violet-500 rounded-lg hover:bg-violet-600 '>Traveler's Request Form</button>
    </div>
</div>
<Certificate />
<Certificate2 />
<div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-4xl p-4">
        <h1 className="text-2xl font-bold text-center mb-4">Traveler's Request Form</h1>
        <div className="relative overflow-hidden" style={{ paddingTop: '56.25%' }}>
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSdLuKhq4HnjdmXXVc0P2BDoFPKgwkkmhnXLCBmuSKaof1p4fQ/viewform"
            className="absolute top-0 left-0 w-full h-full"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            title="Google Form"
            allowFullScreen
          >
            Loading…
          </iframe>
        </div>
      </div>
    </div>
</div>
  )
}

export default AboutUs