import React from 'react'
import  Cert2 from '../Assets/Cert2.jpeg'
const Certificate2 = () => {
  return (
    <div>
          <div>
        <section class="text-gray-600 body-font">
  <div class="container mx-auto flex px-5 py-8 md:flex-row flex-col items-center">
  <img class="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src={Cert2} />
    <div class="w-full md:w-2/3 flex flex-col mb-16 items-center text-center">
      <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Ministry of Tourism - India (Certificate)</h1> 
      <p class="mb-8 leading-relaxed">We are aware of Travel for LIFE, a program under Mission LIFE to create awareness about sustainable tourism. I/We solemnly commit to conducting my/our business in a sustainable manner that positively impacts the environment, society, culture, and economy of the country. I/We will sensitize tourists/guests about Travel for LIFE Actions. I/We hereby declare to take action in a manner to move forward toward Travel for LiFE certification.</p>   
   </div>
  </div>
</section>
    </div>
   </div>
  )
}

export default Certificate2