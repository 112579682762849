import React from 'react'
import  Cert1 from '../Assets/Cert1.jpeg'
const Certificate = () => {
  return (
    
    <div>
        <section class="text-gray-600 body-font">
  <div class="container mx-auto flex px-5 py-8 md:flex-row flex-col items-center">
  <img class="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src={Cert1} />
    <div class="w-full md:w-2/3 flex flex-col mb-16 items-center text-center">
      <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Ministry of Tourism - India (Certificate)</h1> 
      <p class="mb-8 leading-relaxed">We solemnly pledge and reiterate our commitment to conduct our business in a manner that befits the culture and ethos of our rich and ancient civilization and the tolerant and accommodat- ing nature of our multicultural society and protect all individuals, especially women and children from all derogatory acts which are contrary to the spirit of our country. I/We hereby commit to abide by the Code of Conduct for Safe and Honourable Tourism.</p>  
   </div>
  </div>
</section>
    </div>
  )
}

export default Certificate